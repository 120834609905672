import React from "react";
import "./Advertisement.sass";

export const Advertisement = ({ controller }) => {
  let currentDaysBetween = controller.getDaysBetween();
  let endLaunchDateString = controller.getEndLaunchDateString();
  let { title, subtitle } = controller.getTitleSubtitle();
  let ended = controller.getLaunchState() === "pre-launch-ended";
  let last = controller.getLaunchState() === 'pre-launch-last';

  if (ended) {
    return <></>;
  }

  return (
    <div className="advertisement">
      <h3>🚀 {title}</h3>

      <p>{subtitle}</p>

      <p>A lot of devs get stuck in “best practices”.</p>
      <p>Between things like SOLID, Clean Code, microservices, single-file components, and so on — there’s a lot you can do... <br/><br/>But in my experience, there’s only a small set of things that really matter at this stage when it comes to making the shift from <i>coding</i> to crafting.</p>
    <p>There's only a few things that really move the needle in terms of writing scalable, testable code (on any side of the stack).</p>

<p>That's what the Best Practice-First Phase is all about: the <i>real</i> best practices.</p>
<p>We're doing a 55% off promo of The Software Essentialist from March 31st to April 4th to celebrate the launch of The Best Practice-First phase of craftship in the Early Adopter course.</p>
<p>Only 200 spots available. Join the waitlist <a href="https://www.essentialist.dev/master-the-essentials-of-software-design-with-typescript">here</a> 🔗.</p>
<br/>
<p><i>PS: You can track updates & announcements on the course progress & the companion book (solidbook.io / name changing to "crafters") <a href="https://khalilstemmler.notion.site/The-Software-Essentialist-Announcements-81792490e4f34bdd8aedfee2efefc9a0?pvs=74" target="blank">here</a>.</i></p>

    </div>
  );
};


/**
 *       <p>
        You'll build a <b>solid foundation</b> based on the{" "}
        <b>most important parts</b> of topics like:{" "}
        <i>
          Domain-Driven Design, Test-Driven Development, BDD, Object Design,
          Functional Programming, Design Principles, Design Patterns,
          Architectural Styles, Deployment Pipelines
        </i>{" "}
        and more.
      </p>

      <p>
        You'll learn how to apply the essentials to a <b>backend API</b>,{" "}
        <b>frontend React app</b>, <b>chrome extension</b>,{" "}
        <b>custom integration</b>, <b>desktop Electron app</b>, and get the
        opportunity to pair up with other crafters in a private community to
        build your own projects.
      </p>

      <p>Skills {">"} tools. </p>
      <p>
        Become a confident crafter
        <i>
          {" "}
          - without endless books, YouTube videos, courses, or having to learn
          any new frameworks or libraries.
        </i>
      </p>
 */