
import React from 'react';
import moment from 'moment/moment';

/**
 * This would make for a great React component to test
 */

const daysBetween = (date_1, date_2) => {
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
}

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

const advertisementConfig = {
  currentDate: '',
  launchDateStart: '',
  launchDateEnd: '',
  lastPeriodStart: '',
  lastPeriodEnd: ''
}

export class AdvertisementController {

  constructor (config) {
    this.currentDate = config.currentDate ? config.currentDate : new Date()
    this.launchDateStart = config.launchDateStart ? config.launchDateStart : new Date('March 31 2024')
    this.launchDateEnd = config.launchDateEnd ? config.launchDateEnd : new Date('April 4 2024 23:58:00');
    this.lastPeriodStart = config.lastPeriodStart ? config.lastPeriodStart : new Date('April 12 2024 00:00:00'); 
    this.lastPeriodEnd = config.lastPeriodEnd ? config.lastPeriodEnd : new Date('April 14 2024 23:59:00'); 
  }

  getDaysBetween (firstDate, secondDate) {
    firstDate = firstDate ? firstDate : this.launchDateStart
    secondDate = secondDate ? secondDate : this.currentDate
    return daysBetween(firstDate, secondDate);
  }
  
  getLaunchState () {
    let currentDate = this.currentDate 
    let launchDateStart = this.launchDateStart;
    let launchDateEnd = this.launchDateEnd;

    const isPreRegistration = currentDate.getTime() < launchDateStart.getTime();
    const isPreLaunch = currentDate.getTime() >= launchDateStart.getTime() && currentDate <= launchDateEnd.getTime();
    const isEndLaunch = currentDate.getTime() >= launchDateEnd.getTime();
    const isBackForLastPeriod = currentDate.getTime() === this.lastPeriodStart.getTime();
    const launchDay = datesAreOnSameDay (currentDate, launchDateStart);
    
    if (launchDay) return 'is-launch-day'
    if (isBackForLastPeriod) return 'launch-last-period'
    if (isEndLaunch) return 'pre-launch-ended'
    if (isPreRegistration) return 'pre-registration';
    if (isPreLaunch) return 'pre-launch';
    
  }

  getEndLaunchDateString () {
    return moment(this.endLaunchDate).format('MMM D, YYYY')
  }

  getTitleSubtitle () {
    let launchState = this.getLaunchState();
    let currentDaysBetween = this.getDaysBetween(this.launchDate, this.currentDate);
    let hasLaunched = currentDaysBetween >= 0;
    let isOneDayBeforeLaunch = currentDaysBetween === 1;
    let daysBetweenNowAndEnd = this.getDaysBetween(this.launchDateEnd, this.currentDate);
    let daysBetweenLastLaunch = this.getDaysBetween(this.lastPeriodEnd, this.currentDate);

    console.log(daysBetweenLastLaunch)


    if (launchState === 'launch-last-period') {
      return {
        title: `Last call. Registrations close April 13th @ midnight`,
        subtitle: (<>We've re-opened registration for a bit longer. <i>The Software Essentialist</i>: the ultimate course for professional TypeScript developers closes @ midnight, April 13th.</>)
      } 
    }
    
    if (launchState === 'pre-registration' && !isOneDayBeforeLaunch) {
      return {
        title: `55% off The Software Essentialist sale - doors open in ${currentDaysBetween} days`,
        subtitle: ('')
      }
    }
    if (isOneDayBeforeLaunch) {
      return {
        title: `Get ready... tomorrow...`,
        subtitle: (<>I'm opening registrations for <i>The Software Essentialist</i>: the ultimate course for professional TypeScript developers tomorrow (March 31st).</>)
      }
    }

    if (hasLaunched && launchState !== 'isEndLaunch') {
      return {
        title: `It's finally here, registration ends in ${daysBetweenNowAndEnd} days`,
        subtitle: (<>We've just opened registrations for a 55% off sale of <i>The Software Essentialist</i>: the ultimate course for professional TypeScript, React & Node.js developers.</>)
      }
    }

    if (daysBetweenLastLaunch === 0) {
      return {
        title: `Registration closes tomorrow (> 24hrs)`,
        subtitle: (<>Registrations for <i>The Software Essentialist</i>: the ultimate course for professional TypeScript developers ends tomorrow at midnight, April 13th.</>)
      }
    }

    return {
      title: `It's finally here, registration ends in ${daysBetweenNowAndEnd} days`,
      subtitle: (<>We've just opened registrations for the 55% off sale of <i>The Software Essentialist</i>: the ultimate course for professional TypeScript, React & Node.js developers.</>)
    }
  }

}

